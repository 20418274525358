<template>
  <q-input
    v-model="date"
    :label="label ? label : (showFormatHint ? displayMask : undefined)"
    :mask="inputMask"
    :hint="hint || (showFormatHint && !!label ? displayMask : undefined)"
    :hide-bottom-space="hideBottomSpace"
    :disable="disable"
    :rules="inputRules"
    :filled="filled"
    :outlined="outlined"
    :bg-color="bgColor"
    :dense="dense"
    :name="inputName"
    :test-id="inputTestId"
  >
    <template #append>
      <q-icon
        name="event"
        class="cursor-pointer"
      >
        <q-popup-proxy
          ref="qDateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            v-model="date"
            :mask="mask"
            :options="validDatesFn()"
            :locale="pickerLocale"
            :title="pickerTitle"
            :default-year-month="defaultYearMonth"
          >
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                :label="t('actions.close')"
                color="primary"
                outline
                class="wlk-btn"
              />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>
<script>
import { computed, onBeforeMount, ref, toRefs, watch } from 'vue'
import { date as dateUtils, useQuasar } from 'quasar'
import { useI18n } from 'vue-i18n'
import deDE from 'quasar/lang/de'
import moment from 'moment'

export default {
  name: 'I18nDateInput',
  inheritAttrs: true,
  props: {
    modelValue: {
      type: Date,
      default: () => null
    },
    label: {
      type: String,
      default: () => ''
    },
    maskType: {
      type: String,
      default: () => 'medium'
    },
    showFormatHint: {
      type: Boolean,
      default: () => true
    },
    hint: {
      type: String,
      default: () => null
    },
    minDate: {
      type: Date,
      default: () => null
    },
    maxDate: {
      type: Date,
      default: () => null
    },
    disable: {
      type: Boolean,
      default: () => false
    },
    rules: {
      type: Array,
      default: () => []
    },
    filled: {
      type: Boolean,
      default: () => false
    },
    outlined: {
      type: Boolean,
      default: () => false
    },
    hideBottomSpace: {
      type: Boolean,
      default: () => false
    },
    bgColor: {
      type: String,
      default: () => null
    },
    dense: {
      type: Boolean,
      default: () => false
    },
    inputName: {
      type: String,
      default: () => undefined
    },
    inputTestId: {
      type: String,
      default: () => undefined
    },
    defaultYearMonth: {
      type: String,
      default: () => undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup (props, { emit }) {
    const { d, mergeLocaleMessage, t, locale: i18nLocale } = useI18n()
    const q = useQuasar()

    const propRefs = toRefs(props)

    mergeLocaleMessage('de-de', {
      validation: {
        invalid: '{format}',
        tooLow: 'Wählen Sie ein Datum ab {date}',
        tooHigh: 'Wählen Sie ein Datum bis {date}'
      },
      actions: {
        close: 'Schließen'
      }
    })

    mergeLocaleMessage('en-us', {
      validation: {
        invalid: '{format}',
        tooLow: 'Select a date later than or equal to {date}',
        tooHigh: 'Select a date earlier than or equal to {date}'
      },
      actions: {
        close: 'Schließen'
      }
    })

    const date = ref('')
    const datetimeFormats = ref({
      'de-de': {
        short: {
          mask: 'D.M.Y',
          displayMask: 'T.M.J',
          pickerTitle: 'ddd, DD. MMM'
        },
        medium: {
          mask: 'DD.MM.YYYY',
          displayMask: 'TT.MM.JJJJ',
          pickerTitle: 'ddd, DD. MMM'
        },
        pickerLocale: Object.assign(deDE.date, {
          monthsShort: 'Jan_Feb_Mrz_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dez'.split('_')
        })
      },
      'en-us': {
        short: {
          mask: 'M/D/Y',
          displayMask: 'M/D/Y',
          pickerTitle: 'ddd, MMM DD'
        },
        medium: {
          mask: 'MM/DD/YYYY',
          displayMask: 'MM/DD/YYYY',
          pickerTitle: 'ddd, MMM DD'
        }
      }
    })

    const mask = computed(() => getMask().mask)

    const displayMask = computed(() => {
      const mask = getMask()
      return mask.displayMask || mask.mask
    })

    const inputMask = computed(() => mask.value.replace(/[A-Za-z]/g, '#'))

    const inputRules = computed(() => {
      return [
        // eslint-disable-next-line @intlify/vue-i18n/no-missing-keys
        v => (!v || v === formatted.value) || t('validation.invalid', { format: displayMask.value }),
        v => {
          const date = dateUtils.extractDate(v, mask.value)
          date.setHours(0, 0, 0, 0)
          // eslint-disable-next-line @intlify/vue-i18n/no-missing-keys
          return propRefs.minDate.value && v ? (date >= propRefs.minDate.value || t('validation.tooLow', { date: d(propRefs.minDate.value, 'short') })) : true
        },
        v => {
          const date = dateUtils.extractDate(v, mask.value)
          date.setHours(0, 0, 0, 0)
          // eslint-disable-next-line @intlify/vue-i18n/no-missing-keys
          return propRefs.maxDate.value && v ? (date <= propRefs.maxDate.value || t('validation.tooHigh', { date: d(propRefs.maxDate.value, 'short') })) : true
        },
        ...propRefs.rules.value
      ]
    })

    const dateValue = computed(() => {
      const mnt = moment(date.value, mask.value, true)
      return mnt.isValid() ? mnt.toDate() : null
    })

    const formatted = computed(() => dateValue.value ? dateUtils.formatDate(dateValue.value, mask.value) : '')

    const pickerLocale = computed(() => datetimeFormats.value[i18nLocale.value].pickerLocale)

    const pickerTitle = computed(() => dateValue.value ? new Intl.DateTimeFormat(q.lang.isoName, { weekday: 'short', day: 'numeric', month: 'short' }).format(dateValue.value) : '-')

    onBeforeMount(() => {
      if (propRefs.minDate.value > propRefs.maxDate.value) { date.value = ''; return }

      date.value = dateUtils.formatDate(propRefs.modelValue.value, mask.value)
    })

    watch(propRefs.modelValue, (value) => {
      if (propRefs.minDate.value > propRefs.maxDate.value) { date.value = ''; return }
      if (value) {
        date.value = dateUtils.formatDate(value, mask.value)
      }
    })

    watch(dateValue, (value) => {
      emit('update:modelValue', value)
    })

    // reformat the date string because locale change means mask change
    watch(i18nLocale, (newLocale, oldLocale) => {
      if (!date.value) {
        return
      }

      const oldMask = getMask(oldLocale).mask
      const newMask = getMask(newLocale).mask

      const transformed = dateUtils.extractDate(date.value.valie, oldMask)
      date.value = dateUtils.formatDate(transformed, newMask)
    })

    function validDatesFn () {
      return date => {
        // It's always the default mask?!
        date = dateUtils.extractDate(date, 'YYYY/MM/DD')
        date.setHours(0, 0, 0, 0)
        return (propRefs.minDate.value ? (propRefs.minDate.value <= date) : true) && (propRefs.maxDate.value ? (propRefs.maxDate.value >= date) : true)
      }
    }

    function getMask (locale = i18nLocale.value) {
      return datetimeFormats.value[locale][propRefs.maskType.value]
    }

    return {
      inputRules,
      pickerLocale,
      pickerTitle,
      validDatesFn,
      // mask
      mask,
      displayMask,
      inputMask,
      // date
      date,
      d,
      t
    }
  }
}
</script>
