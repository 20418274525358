import { QInput, QSelect } from 'quasar'
import I18nDateInput from 'src/components/i18nDateInput.vue'

/**
 * Set some default properties on a component
 */
const setComponentDefaults = (component, defaults) => {
  Object.keys(defaults).forEach((prop) => {
    component.props[prop] =
      Array.isArray(component.props[prop]) === true || typeof component.props[prop] === 'function'
        ? { type: component.props[prop], default: (defaults)[prop] }
        : { ...component.props[prop], default: (defaults)[prop] }
  })
}

function setCommonDefaults () {
  setComponentDefaults(QInput, {
    outlined: true,
    labelColor: 'dark',
    debounce: 300
  })

  setComponentDefaults(QSelect, {
    outlined: true,
    labelColor: 'dark'
  })

  setComponentDefaults(I18nDateInput, {
    outlined: true,
    labelColor: 'dark'
  })
}

export {
  setCommonDefaults
}
